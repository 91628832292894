html {
  height: 100%;
  font-size: 100%;
  /* font-size: 62.5%; */
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
* {
  outline: none;
}

strong,
b {
  font-weight: 700;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-size: 13px;
  font-size: 1.3rem; */
  height: 100%;
  font-weight: 400;
  position: relative;

  color: rgba(0, 0, 0, 0.87);
  /* font-size: 0.875rem; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



/*app animations*/
/*earlier we have defined in App.sytle.js, but we got the issue*/
/*issue : when we were using animation by injection in another mystyle() by ... operator the priority of loading css was changed*/
/*we tried to remove ... injection but after that animation was not working*/
/*still not sure how to make it working*/
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes spin {
  0% {
    transform: rotate(355deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeInX {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
